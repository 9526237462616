/* eslint-disable prettier/prettier */
import React from "react";
import Text from "@/bit/components/components.text";

const Header6Title = (props) => {
  const { title, theme, textProps, loadingHeaderLocation } = props;
  if (!theme) {
    return null;
  }
  let fontType, size, color;
  switch (theme) {
    case "areaprivada":
      size = "title02";
      color = "white";
      fontType = "font-regular";
      break;
    case "detalle":
      size = "title02";
      color = "dark-blue";
      fontType = "font-bold";
      break;

    default:
      size = "title03";
      color = "dark-blue";
      fontType = "font-bold";
  }

  let skeletonClassName = "skeleton loaded";
  if (loadingHeaderLocation) {
    skeletonClassName = "skeleton";
  }

  return (
    <>
      <div className="header6-title">
        <div className={theme}>
          <div className="container">
            <Text
              {...textProps}
              text={title}
              fontType={fontType}
              size={size}
              color={color}
            />
            <div className={skeletonClassName} />
          </div>
        </div>
        <style jsx>{`.header6-title {
  .container{
    .areaprivada {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background-color: #019df4;
    }
    .default {
      :global(.text) {
        text-wrap: balance;
      }
    }
    .skeleton {
      position: absolute;
      top:0px;
      left:12px;
      height:100%;
      width:75vw;
    }
  }
}

@media screen and (min-width: 768px) {
  .header6-title {
    .container{
      .areaprivada {
        padding-top: 0rem;
        padding-bottom: 0rem;
        background-color: #019df4;
      }
    }
  }
}
`}</style>
      </div>
    </>
  );
};
export default Header6Title;
