import React from "react";
import PropTypes from "prop-types";
import CustomLink from "@/bit/components/components.custom-link";
import Glyph from "@/bit/components/components.icon-glyph";
import Text, { TEXT_COLOR } from "@/bit/components/components.text";
import { COLOR_DICTIONARY } from "@gcloud-npm/utils.enums.colors";
import { TAGGIN_INFO } from "../../constants";

const InternalLinkItem = ({
  link,
  text,
  target,
  icon,
  iconWidth,
  iconHeight,
}) => {
  return (
    <>
      <div className="internal-link-item">
        <CustomLink
          href={link}
          taggingInfo={{ ...TAGGIN_INFO, componentContent: text }}
          target={target}
          className="link"
          cyData={`Footer link ${text}`}
        >
          {icon && (
            <Glyph
              id={icon}
              width={iconWidth}
              height={iconHeight}
              className="link-logo"
              color={COLOR_DICTIONARY.GREY3}
            />
          )}

          {text && <Text text={text} color={TEXT_COLOR.GREY3} />}
        </CustomLink>
      </div>
      <style jsx>{`.internal-link {
  padding: 0.5rem 0;
}
:global(.link) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .internal-link {
    padding: 0;
  }
  .internal-link :global(.link) {
    gap: 1.5rem;
  }
}
`}</style>
    </>
  );
};

export default InternalLinkItem;

InternalLinkItem.propTypes = {
  /**
   * {
   *  "info": "Dirección URL a la que lleva el link.",
   *  "kind": "both",
   *  "beautifulName": "Link URL"
   * }
   */
  link: PropTypes.string,

  /**
   * {
   *  "info": "Texto del link",
   *  "kind": "both",
   *  "beautifulName": "Texto de el Link"
   * }
   */
  text: PropTypes.string,

  /**
   * {
   *  "info": "Etiqueta HTML target que se incluye en el link",
   *  "kind": "both",
   *  "beautifulName": "Target"
   * }
   */
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
};

InternalLinkItem.defaultProps = {
  link: "",
  text: "",
  target: "_self",
  icon: "grupo-s",
  iconWidth: 28,
  iconHeight: 28,
};
