import { TEXT_KIND } from "./enums";

export function extendTextInfoClassNames({
  segment,
  kind,
  size,
  sizeLegacy,
  fontType,
  align,
  display,
  wrap,
  border,
  decoration
}) {
  let finalCssClassName = "";
  if (kind) {
    finalCssClassName = `${finalCssClassName} text-kind--${kind}`;
  }
  if (align) {
    finalCssClassName = `${finalCssClassName} text-align--${align}`;
  }
  if (display) {
    finalCssClassName = `${finalCssClassName} text-display--${display}`;
  }
  if (wrap) {
    finalCssClassName = `${finalCssClassName} text-wrap--${wrap}`;
  }
  if (size && !sizeLegacy) {
    finalCssClassName = `${finalCssClassName} text-size--${size}`;
  }
  if (size && sizeLegacy) {
    finalCssClassName = `${finalCssClassName} text-size_legacy--${size}`;
  }
  if (fontType) {
    finalCssClassName = `${finalCssClassName} text-${segment}--${fontType}`;
  }
  if (border) {
    finalCssClassName = `${finalCssClassName} text-border--${border}`;
  }
  if (decoration) {
    finalCssClassName = `${finalCssClassName} text-decoration--${decoration}`;
  }
  return finalCssClassName;
}

const isHtml = (text) => {
  const regex = /<\/?[a-z][\s\S]*>/i;
  return regex.test(text);
};

export const getProperKind = (kind, text) => {
  const hasHtmlContent = isHtml(text);
  if (hasHtmlContent) {
    kind = TEXT_KIND.DIV;
  }
  switch (kind) {
    case "span":
      return TEXT_KIND.SPAN;
    case "h":
    case "heading":
      return TEXT_KIND.HEADING;
    case "p":
    case "paragraph":
      return TEXT_KIND.PARAGRAPH;
    case "div":
      return TEXT_KIND.DIV;
    default:
      return TEXT_KIND.SPAN;
  }
};
