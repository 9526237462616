import React from "react";
import DefaultMenu from "../../menu/layouts/default";
import CPMenu from "../../menu/layouts/cp";
import { MENU_LAYOUTS } from "../../textConstans";

const Header2Nav = (props) => {
  const {
    logged,
    cp,
    navSections,
    segmentMenu,
    municipios,
    municipio,
    showCP,
    forceSelect,
  } = props;
  const selector = logged ? "logged" : "default";
  return (
    <div className="container">
      <div className="header2-nav">
        <div className="menu">
          <ul className="section-menu" data-cy="Header links">
            {navSections && navSections[selector] && (
              <DefaultMenu
                items={navSections[selector]}
                layout={MENU_LAYOUTS.DEFAULT}
                forceSelect={forceSelect}
                location="main"
              />
            )}
          </ul>
          <ul className="segment-menu" data-cy="Header secondary links">
            {segmentMenu && segmentMenu[selector] && (
              <DefaultMenu
                items={segmentMenu[selector]}
                layout={MENU_LAYOUTS.DEFAULT}
                forceSelect={forceSelect}
                location="main"
              />
            )}
          </ul>
        </div>
        {showCP && (
          <ul className="options">
            <CPMenu
              layout={MENU_LAYOUTS.CP}
              location="menu"
              cp={cp}
              municipios={municipios}
              municipio={municipio}
            />
          </ul>
        )}
      </div>
      <style jsx>{`.header2-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  :global(.menu) {
    display: none;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &::-webkit-scrollbar {
      display: none;
    }

    :global(.menu-item.selected) {
      :global(.text) {
        color: #313235 !important;
        text-decoration: solid underline currentColor 2px;
        text-underline-offset: 4px;
        text-decoration-skip-ink: none;
      }
    }
    :global(.menu-item) {
      :global(.text:hover) {
        color: #313235 !important;
        text-decoration: solid underline currentColor 1px;
        text-underline-offset: 4px;
        text-decoration-skip-ink: none;
      }

      :global(.text:active) {
        color: #313235 !important;
        text-decoration: solid underline currentColor 1px;
        text-underline-offset: 4px;
        text-decoration-skip-ink: none;
      }
    }
  }
  .options {
    display: flex;
    align-items: center;

    :global(button:focus) {
      outline: none;
    }

    :global(.menu-item) {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      :global(.text-gap) {
        :global(.text) {
          &:hover {
            color: #313235 !important;

            @media (max-width: 768px) {
              color: #019df4 !important;
            }
          }
        }
      }

      :global(.close) {
        padding-left: 0.5rem;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .header2-nav {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    :global(.menu) {
      display: flex;

      :global(.section-menu) {
        display: none;
        gap: 1rem;

        :global(.menu-item) {
          display: inline;
        }
      }

      :global(.segment-menu) {
        display: none;
      }
    }

    .options {
      justify-content: flex-end;
      gap: 0.5rem;

      :global(.menu-item) {
        :global(.text-gap) {
          :global(.text) {
            color: #6b6c6f !important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .header2-nav {
    :global(.menu) {
      :global(.section-menu) {
        display: flex;

        :global(.menu-item) {
          display: inline;
        }
      }
    }
  }
}
@media screen and (min-width: 1280px) {
  .header2-nav {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    gap: 0.5rem;

    :global(.text) {
      text-wrap: nowrap;
    }

    :global(.menu) {
      max-height: 40px;
      overflow: hidden;

      :global(.section-menu) {
        gap: 1.5rem;

        :global(.menu-item) {
          display: inline;
        }
      }

      :global(.segment-menu) {
        display: flex;
        flex-flow: row wrap;
        row-gap: 1rem;

        :global(.next-link) {
          display: flex;

          &:last-child {
            display: none;
          }

          &:first-child {
            display: flex;
          }

          &::before {
            display: inline-block;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            color: #6c757d;
            content: "|";
          }
        }
      }
    }
  }
}
`}</style>
    </div>
  );
};
export default Header2Nav;
