import React, { Component } from "react";
import MainLinkList from "./main-link-list";
import SecondaryLinkList from "./secondary-link-list";
import SocialMedia from "./social-media";
import InternalLinks from "./internal-links";
import MainLogo from "./main-logo";

class Footer extends Component {
  constructor() {
    super();
  }

  render() {
    const { footerProps } = this.props;
    if (!footerProps || process.env.NEXT_PUBLIC_WEB_VIEW === "true") {
      return null;
    }

    const mediaLinks = footerProps["media-links"];

    return (
      <>
        <footer className="footer">
          <div className="block-1">
            <MainLinkList data={footerProps["main-link-list"]} />
          </div>
          <div className="block-2">
            <div className="content container">
              <div className="media-container">
                <SocialMedia data={mediaLinks["social-media"]} />
                <span className="separator-container">
                  <span />
                </span>

                <InternalLinks internalLinks={mediaLinks["internal-links"]} />
              </div>
              <span className="separator-horizontal" />
              <SecondaryLinkList data={footerProps["secondary-link-list"]} />
              <MainLogo {...mediaLinks["main-logo"]} />
            </div>
          </div>
          <style jsx>{`.footer {
  .block-1 {
    background-color: #eee;
  }
  .block-2 {
    background-color: #0b2739;
  }

  .block-2 .content .media-container .separator-container {
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .block-2 .content .media-container .separator-container > span {
    display: block;
    width: 50%;
    height: 1px;
    background-color: #86888c;
  }
  .block-2 .content .separator-horizontal {
    display: block;
    margin: 0 auto;
    width: 80%;
    height: 1px;
    background-color: #86888c;
  }
  @media screen and (min-width: 768px) {
    .block-2 .content .media-container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      position: relative;
      margin: 0 auto;
    }

    .block-2 .content .separator-horizontal {
      width: 100%;
    }
  }
  @media screen and (min-width: 1024px) {
    .block-2 .content .media-container .separator-container {
      display: block !important;
      align-self: stretch;
      width: 1px;
      margin: 0 !important;
      background-color: #86888c;
    }
    .block-2 .content .media-container .separator-container > span {
      background-color: transparent !important;
    }
  }
}
`}</style>
        </footer>
      </>
    );
  }
}

export default Footer;
