import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import Sticky from "./sticky";
import Header1Main from "./containers/header1-main";
import Header2Nav from "./containers/header2-nav";
import Header3Breadcrumb from "./containers/header3-breadcrumb";
import Header4Section from "./containers/header4-section";
import Header5SecondaryMenu from "./containers/header5-secondary-menu";
import Header6Title from "./containers/header6-title";
import SecureStorage from "@gcloud-npm/utils.secure.local-storage";
import { connect } from "react-redux";
import getCookie from "@gcloud-npm/utils.common.get-cookie";
const PseudoModalLogin = dynamic(() => import("./modals/prompt-to-login"));
import { P2LOGIN_EXCEPTIONS_URLS , getPrefix,TIME_TO_WAIT } from "./textConstans";

let maxRetries = 1;
const Header = (props) => {
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const {
    logged,
    userName,
    userType,
    contextLoaded,
    mainOptions,
    navSections,
    segmentMenu,
    burgerSections,
    burgerOptions,
    account,
    pills,
    pillsLogged,
    breadcrumbs,
    secondaryMenu,
    location,
    cookiesHandled ,
    //Fuerza la seleccion de un item del header (por text), omitiendo el matcheo automatico entre url y breadcrumb
    forceSelect,
    showCP = true,
    extraBreadcrumbsProps = null,
    loadingHeaderLocation = false,
    logoutBackToHome = false,
    privateArea = false,
    hideNavMenu = false,
  } = props;

  const onCloseHandler = () => {
    sessionStorage.setItem("loginPrompt", "-1");
    setShowLoginPrompt(false);
  };

  const checkPermittedURL = () => {
    const prefix = getPrefix();
    let urlTokens = window.location.pathname.split("/");
    urlTokens = urlTokens.filter((item) => !!item);
    if (urlTokens && urlTokens[0]){
      urlTokens = urlTokens[0]
    } else {
      urlTokens = ''
    }
    let url = prefix + "/" +urlTokens;
    const allUrls = P2LOGIN_EXCEPTIONS_URLS(prefix);
    return allUrls.indexOf(url) === -1;
  };

  useEffect(() => {
    if (maxRetries > 0 || cookiesHandled) {
      maxRetries = maxRetries - 1;
      const isPermittedURL = checkPermittedURL();
      const cookiesAcepted = getCookie("OptanonConsent");
  
      if ((cookiesAcepted && isPermittedURL) || cookiesHandled) {
        let nowts = Date.now();
        const secureStorage = new SecureStorage();
        const secureData = secureStorage.getItem("ts");
        const isNotLogged =
          secureData && (!secureData.userType || secureData.userType === "");
  
        let loginPromptValue = sessionStorage.getItem("loginPrompt");
  
        let timeHasExpired = Math.abs(parseInt(loginPromptValue, 10) - nowts);
  
        let cndShouldShowLoginPrompt =
          loginPromptValue != "-1" && isNotLogged && timeHasExpired >= TIME_TO_WAIT;
  
        if (isNotLogged && !loginPromptValue) {
          const newts = nowts + TIME_TO_WAIT;
          sessionStorage.setItem("loginPrompt", newts);
          loginPromptValue = newts;
          let idTimeOut = setTimeout(() => {
            clearTimeout(idTimeOut);
            setShowLoginPrompt(true);
          }, TIME_TO_WAIT);
        } else {
          if (cndShouldShowLoginPrompt) {
            setShowLoginPrompt(true);
          }
        }
      }
    }
  }, [cookiesHandled]);

  return (
    <>
      <header className="header">
        <Sticky behaviour="ALL_STICKY">
          <Header1Main
            mainOptions={mainOptions}
            navSections={navSections}
            segmentMenu={segmentMenu}
            burgerSections={burgerSections}
            burgerOptions={burgerOptions}
            accountSections={account}
            logged={logged}
            userName={userName}
            userType={userType}
            breadcrumb={breadcrumbs}
            contextLoaded={!!contextLoaded}
            logoutBackToHome={logoutBackToHome}
            privateArea={privateArea}
            forceSelect={forceSelect}
            location={location}
          />
        </Sticky>
        {!hideNavMenu && (
          <Header2Nav
            navSections={navSections}
            segmentMenu={segmentMenu}
            breadcrumb={breadcrumbs}
            logged={logged}
            showCP={showCP}
            forceSelect={forceSelect}
          />
        )}
        <Header3Breadcrumb
          breadcrumb={breadcrumbs}
          extraBreadcrumbsProps={extraBreadcrumbsProps}
          loadingHeaderLocation={loadingHeaderLocation}
          forceSelect={forceSelect}
        />
        <Header4Section
          pills={pills}
          pillsLogged={pillsLogged}
          logged={logged}
        />
        {secondaryMenu && secondaryMenu.items && (
          <Header5SecondaryMenu secondaryMenu={secondaryMenu} />
        )}
        <Header6Title
          loadingHeaderLocation={loadingHeaderLocation}
          theme={
            breadcrumbs && breadcrumbs["title-theme"]
              ? breadcrumbs["title-theme"]
              : null
          }
          title={
            breadcrumbs && breadcrumbs["title-text"]
              ? breadcrumbs["title-text"]
              : breadcrumbs && breadcrumbs["title"]
              ? breadcrumbs["title"]
              : null
          }
          textProps={
            breadcrumbs && breadcrumbs["textProps"]
              ? breadcrumbs["textProps"]
              : {}
          }
        />
        {showLoginPrompt && (          
            <PseudoModalLogin onCloseHandler={onCloseHandler} />          
        )}
        <style jsx>{`.header {
  position: relative;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  z-index: 27;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .header {
    padding: 0px;
  }
}
`}</style>
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  secondaryMenu: state.header.secondaryMenu,
  hideNavMenu: state.header.hideNavMenu,
  forceSelect: state.header.forceSelect,
  logged: state.main.logged,
  cookiesHandled: !!state.main.context?.clientIDs?.cookiesHandled,
  contextLoaded: state.main.context.contextSeoMeta,
  userName: state.main.context.userName,
  userType: state.main.context.userType,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
