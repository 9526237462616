import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "@/bit/components/components.spin";
import IconGlyph from "@/bit/components/components.icon-glyph";
import SupportAndSearch from "../../modals/support-search";
import { actions as headerActions } from "@/bit/reducers/reducers.header-reducer";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import { getColor } from "@gcloud-npm/utils.enums.colors";

const INPUT_ID = "inputsupportadnsearch";
let QUERY = "";

export const checkIfSearchCompleted = (pagesSuggestedList) => {
  return pagesSuggestedList != null;
};

export const checkIfThereAreResults = ({
  pagesSuggestedList,
  querySuggestedInputs,
  checkPages = true,
  checkAt = true,
}) => {
  let res = false;
  if (checkPages) {
    const thereArePages =
      pagesSuggestedList &&
      ((pagesSuggestedList.regular && pagesSuggestedList.regular.length > 0) ||
        (pagesSuggestedList.services &&
          pagesSuggestedList.services.length > 0) ||
        (pagesSuggestedList.devices && pagesSuggestedList.devices.length > 0));
    res = thereArePages;
  }

  if (checkAt) {
    const checkATInputs =
      querySuggestedInputs && querySuggestedInputs.length > 0;
    res = res || checkATInputs;
  }
  return res;
};

export const handleAtRequest = ({
  label,
  event,
  checkPages,
  checkAt,
  pagesSuggestedList = null,
  querySuggestedInputs = null,
  inputID,
}) => {
  try {
    const inputValue = document.getElementById(inputID).value;
    let query = label || inputValue;
    if (event) {
      event.preventDefault();
    }
    const detectAutocompleteOption =
      document.getElementById("hidden-query").value;
    const shouldSend = !checkIfThereAreResults({
      checkPages,
      checkAt,
      pagesSuggestedList,
      querySuggestedInputs,
    });
    const searchComplete = checkIfSearchCompleted(pagesSuggestedList);
    if (
      ((shouldSend || detectAutocompleteOption) &&
        inputValue &&
        inputValue.length > 0 &&
        inputValue.length < 3) ||
      (searchComplete &&
        (shouldSend || detectAutocompleteOption) &&
        inputValue &&
        inputValue.length > 2)
    ) {
      query = encodeURIComponent(query);
      const name = "searcher";
      const content = encodeURIComponent(`${QUERY}`);
      const variation = detectAutocompleteOption
        ? "asistente-virtual"
        : "asistente-virtual-no-results";

      const urlToRedirect = `https://www.movistar.es/atencion-cliente/sin-resultados/?name=${name}&content=${content}&variation=${variation}`;
      window.location.href = urlToRedirect;
    }
  } catch (error) {}
};
class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.resultModalOpen = false;
    this.state = {
      query: "",
      focus: false,
      loading: false,
    };
    this.ID = props.id ? props.id : INPUT_ID;
  }

  setResultModalOpen = (flag) => {
    this.resultModalOpen = flag;
  };

  setUp = () => {
    const { getSearcherInfo, pagesList } = this.props;

    if (!pagesList) {
      getSearcherInfo();
      this.setState({
        loading: true,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.pagesList && this.props.pagesList) {
      this.setState({
        loading: false,
      });

      if (this.state.query) {
        this.handleSearch(this.state.query);
      }
    }
  };

  componentDidMount = () => {
    // Logica busqueda por parametro url
    const { ignoreParamDesktop } = this.props;
    const device = getDeviceKindDependsOnViewPort();
    if (device !== "MOBILE" || ignoreParamDesktop) {
      const urlParams = new URLSearchParams(window.location.search);
      const queryParam = this.sanitizeInput(urlParams.get("q"));
      const delayParam = urlParams.get("delay");
      if (queryParam) {
        this.setUp();
        this.typeText(queryParam, 0, this.convertToNumber(delayParam));
      }
    }
    const { autoFocus } = this.props;
    if (autoFocus) {
      // hacemos timeout para que funcione en safari
      setTimeout(() => {
        try {
          document.getElementById(this.ID).focus();
        } catch (err) {}
        try {
          document.getElementById(this.ID).click();
        } catch (err) {}
      }, 500);
      this.setUp();
    }
  };
  sanitizeInput = (input) => {
    if (input) {
      return input.replace(/[<>{}]/g, ""); // Remueve etiquetas HTML
    }
    return "";
  };
  convertToNumber = (str) => {
    if (str && !isNaN(str)) {
      return parseFloat(str);
    } else {
      return null;
    }
  };

  typeText = (queryParam, index, delay) => {
    if (index < queryParam.length) {
      this.setState((prevState) => ({
        query: prevState.query + queryParam[prevState.query.length],
      }));
      const finalDelay = delay || 1200;
      setTimeout(() => {
        this.typeText(queryParam, index + 1, delay);
      }, finalDelay / queryParam.length);
    } else this.handleInputChange({ target: { value: queryParam } });
  };

  onCloseHandler = () => {
    this.setResultModalOpen(false);
    this.handleClear({
      withCloseModal: true,
      withQueryReset: true,
    });
  };

  openSearchModal = () => {
    this.setResultModalOpen(true);

    const { addModal } = this.props;
    addModal({
      id: "searcher",
      Element2Show: SupportAndSearch,
      modalProps: {
        contentPosition: "center--top",
        opacityLayoutColor: "blur",
        inSceneEffect: "fadeIn",
        contentInsideContainer: true,
        outSceneEffect: "fadeOut",
        closeAllOnClickOutside: true,
        closeByClickingOutside: true,
        createAnchor: false,
        onCloseHandler: this.onCloseHandler,
      },
      elementProps: {
        visibleInput: false,
      },
    });
  };

  handleSearch = (query) => {
    const {
      getRelatedPages,
      onSearch,
      needOpenModalResults,
      pagesSuggestedList,
      querySuggestedInputs,
    } = this.props;
    // buscamos por paginas
    getRelatedPages(query);

    // comunicamos a quien lo necesite
    if (onSearch) {
      onSearch(query);
    }
    // gestion de modal
    if (needOpenModalResults && !this.resultModalOpen) {
      this.openSearchModal();
      const { autoFocus } = this.props;
      if (autoFocus) {
        document.getElementById(this.ID).focus();
      }
    }
  };

  onInputClick = () => {
    this.setUp();
    this.setState({
      focus: true,
    });
  };
  onBlurClick = () => {
    this.setState({
      focus: false,
    });
  };

  handleClear = ({ withCloseModal = false, withQueryReset = true }) => {
    const { resetSearcher, onSearch, addModal, backButton } = this.props;
    if (withQueryReset) {
      QUERY = "";
      this.setState({ query: "" });
    }
    if (onSearch) {
      onSearch("");
    }
    resetSearcher();
  };

  handleInputChange = (event) => {
    const query = event.target.value;
    if (query && query.length > 1) {
      this.handleSearch(event.target.value);
    } else {
      this.handleClear({
        withCloseModal: false,
        withQueryReset: false,
      });
    }
    QUERY = event.target.value;
    this.setState({
      query: event.target.value,
    });
  };

  render() {
    const { query, focus, loading } = this.state;

    const {
      backButton,
      className,
      placeHolder,
      autoComplete,
      pagesSuggestedList,
      querySuggestedInputs,
    } = this.props;
    return (
      <>
        <form
          className="form-searcher"
          id={`form-${this.ID}`}
          onSubmit={(event) => {
            handleAtRequest({
              event,
              checkAt: true,
              checkPages: true,
              pagesSuggestedList,
              querySuggestedInputs,
              inputID: this.ID,
            });
          }}
        >
          <div
            className={`SearchInput${focus ? " focus" : ""}`}
            data-cy="Header search"
          >
            <button
              name="buscar"
              title="buscar"
              aria-label="buscar"
              type="submit"
              className="search-input-button-cont"
            >
              {loading && query ? (
                <Spinner
                  size="small"
                  operation="loading"
                  fillColor={getColor("grey5")}
                  strokeColor={getColor("grey5")}
                />
              ) : (
                <IconGlyph color="grey5" id="buscar-m" width={22} />
              )}
            </button>
            <label htmlFor="searcher" className="label-input-search">
              {placeHolder}
            </label>
            <input
              id={this.ID}
              className={`${className ? ` ${className} ` : ""} field`}
              type="search"
              name="searcher"
              aria-label={placeHolder}
              value={query}
              onClick={this.onInputClick}
              onBlur={this.onBlurClick}
              autoComplete={autoComplete}
              onChange={this.handleInputChange}
              placeholder={placeHolder}
              data-cy={`Header input search${className ? ` ${className}` : ""}`}
            />
            <input id="hidden-query" type="hidden" />
            {backButton && (
              <button
                className="clearButton"
                type="button"
                onClick={() =>
                  this.handleClear({
                    withCloseModal: false,
                    withQueryReset: true,
                  })
                }
              >
                <IconGlyph color="grey6" id="cerrar-m" width={12} />
              </button>
            )}
          </div>
          <style jsx>{`.form-searcher {
  display: none;
  width: 100%;
  font-family: "Regular";
  .backbutton {
    margin-right: 1rem;
  }
  .SearchInput {
    max-width: 100%;
    width: 100%;
    padding: 0rem 1rem;
    height: auto;
    border: 1px solid #c9c9c9;
    border-radius: 3rem;
    display: flex;
    flex-wrap: nowrap;
    transition: all 0.3s;

    :global(.search-input-button-cont) {
      position: relative;
      min-width: 22px;
    }

    .label-input-search {
      display: none;
    }
    &.focus {
      border-color: #019df4;
      transition: all 0.3s;
    }
    button {
      display: flex;
      align-items: center;
    }
    .field {
      width: 100%;
      border: none;
      height: 2.5rem;
      outline: none;
      padding-left: 1.25rem;
      // font-size: 1rem;
      &:focus {
        border: none;
        outline: none;
      }
    }
    .clear-button:focus {
      border: none;
      outline: none;
    }
  }
}

@media screen and (min-width: 768px) {
  .form-searcher {
    display: flex;
    min-width: 16rem;
    .SearchInput {
      max-width: 90%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .form-searcher {
    .SearchInput {
      max-width: 90%;
    }
  }
}
@media screen and (min-width: 1280px) {
  .form-searcher {
    .SearchInput {
      padding: 0rem 1rem;
      max-width: 90%;
      .field {
        height: 3rem;
        // font-size: 1rem;
      }
    }
  }
}
`}</style>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  querySuggestedInputs: state.header.querySuggestedInputs,
  pagesSuggestedList: state.header.pagesSuggestedList,
  pagesList: state.header.pagesList,
});

const mapDispatchToProps = (dispatch) => ({
  getSearcherInfo: () => dispatch(headerActions.getSearcherInfo()),
  addModal: (data) => dispatch(modalActions.addModal(data)),
  getSuggestedInputs: (query) =>
    dispatch(headerActions.getSuggestedInputs(query)),
  resetSearcher: () => dispatch(headerActions.resetSearcher()),
  getRelatedPages: (query) => dispatch(headerActions.getRelatedPages(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);

SearchInput.propTypes = {
  backButton: PropTypes.bool,
  placeHolder: PropTypes.string,
  autoComplete: PropTypes.string,
  needOpenModalResults: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

SearchInput.defaultProps = {
  backButton: false,
  placeHolder: "Busca en Movistar",
  autoComplete: "off",
  needOpenModalResults: false,
  autoFocus: false,
};
