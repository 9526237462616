import React, { Component } from "react";
import dynamic from "next/dynamic";
import CustomLink from "@/bit/components/components.custom-link";
import Text from "@/bit/components/components.text";
import IconGlyph from "@/bit/components/components.icon-glyph";
import { connect } from "react-redux";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";

const ModalUser = dynamic(() => import("../modals/user"));

class UserPill extends Component {
  constructor(props) {
    super(props);
  }

  openModal = () => {
    const { addModal, items, segmentMenu, options, userName, userType , logoutBackToHome, privateArea } =
      this.props;
    const device = getDeviceKindDependsOnViewPort();
    const fadeIn = device === "MOBILE" ? "fadeInTop" : "fadeInTop";
    const fadeOut = device === "MOBILE" ? "fadeOut" : "fadeOut";
    addModal({
      id: "account",
      Element2Show: ModalUser,
      modalProps: {
        contentPosition: "right--top",
        opacityLayoutColor: "blur",
        inSceneEffect: fadeIn,
        closeIdByClickingOutside: "",
        contentInsideContainer: true,
        outSceneEffect: fadeOut,
        closeByClickingOutside: true,
        createAnchor: false,
      },
      elementProps: {
        items: { ...items },
        userName: userName,
        userType: userType,
        logoutBackToHome: logoutBackToHome,
        privateArea: privateArea,
      },
    });
    this.setState({
      modalOpened: true,
    });
  };

  render() {
    const { userName } = this.props;

    return (
      <>
        <div className="user-pill">
          <CustomLink
            onClick={this.openModal}
            title="Mi cuenta"
            ariaLabel="Mi cuenta"
            className="button"
            taggingInfo={{
              componentName: "header",
              componentContent: "openAccount",
              componentVariation: "all",
              componentPerso: "",
              componentProduct: "",
            }}
          >
            <div className="user-icon">
              <IconGlyph color="white" id="usuario-m" width={20} height={20} />
            </div>
          </CustomLink>
          <div className="user-content">
            <div className="user-name"> 
                <Text
                    text={`Hola, ${userName}`}
                    size="caption"
                    display="block"
                  />
            </div>
            <div className="user-menu">
              <div className="item">
                <CustomLink
                  onClick={this.openModal}
                  title="Mi cuenta"
                  ariaLabel="Mi cuenta"
                  taggingInfo={{
                    componentName: "header",
                    componentContent: "openAccount",
                    componentVariation: "all",
                    componentPerso: "",
                    componentProduct: "",
                  }}
                >
                  <Text
                    text="Mi cuenta"
                    fontType="font-regular"
                    size="caption"
                    color="blue"
                    display="block"
                  />
                </CustomLink>
              </div>
            </div>
          </div>
          <style jsx>{`.user-pill {
  margin-left: 1rem;
  .user-icon {
    background-color: #019df4;
    display: flex;
    min-width: 2.5rem;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    border-radius: 50%;
    button:focus {
      outline: none;
    }
  }
  .user-content {
    display: none;
    .user-name {
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: "Regular";
      max-width: 150px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      color: #313235;
    }
  }
}

@media screen and (min-width: 1024px) {
  .user-pill-close {
    display: flex;
    min-width: 13.5rem;
    align-items: center;
    justify-content: flex-end;
  }
  .user-pill {
    display: flex;
    align-items: center;
    min-width: 11.25rem;
    padding: 0rem 0.25rem;
    padding-right: 0;
    height: 3rem;
    border: 1px solid #c9c9c9;
    border-radius: 3rem;
    flex-wrap: nowrap;
    transition: all 0.3s;
    gap: 0.25rem;
    .user-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline;
      width: 100%;
      .text {
        // font-size: 0.875rem;
      }
      .user-menu {
        display: flex;
        margin-top: 0.2rem;
        .item {
          display: inline-flex;
          &:not(:last-child)::after {
            display: inline-block;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            color: #dddddd;
            content: "|";
          }
          &:hover {
            color: #019df4;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .user-pill {
    height: 3.5rem;
    padding: 0rem 0.5rem;
    min-width: 13.5rem;
  }
}
`}</style>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modals: state.modals.modalHistory,
});

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPill);
