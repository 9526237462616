import React from "react";
import Breadcrumb from "../../breadcrumb";

const Header3Breadcrumb = (props) => {
  const { breadcrumb, loadingHeaderLocation, extraBreadcrumbsProps } = props;

  let skeletonClassName = "skeleton loaded";
  if (loadingHeaderLocation) {
    skeletonClassName = "skeleton";
  }
  if (!breadcrumb) {
    return null;
  }

  return (
    <div className="container">
      <div className="header3-breadcrumb">
        <Breadcrumb
          skeletonClassName={skeletonClassName}
          breadcrumb={breadcrumb}
          extraBreadcrumbsProps={extraBreadcrumbsProps}
        />
      </div>
      <style jsx>{`.header3-breadcrumb {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media screen and (min-width: 768px) {
  .header3-breadcrumb {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media screen and (min-width: 1280px) {
  .header3-breadcrumb {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
`}</style>
    </div>
  );
};
export default Header3Breadcrumb;
