export const TEXT_KIND = {
  HEADING: "h",
  PARAGRAPH: "p",
  SPAN: "span",
  DIV: "div",
};

export const TEXT_DISPLAY = {
  INLINE: "inline",
  BLOCK: "block",
  DEFAULT: "default",
  NONE: "none",
};

export const TEXT_WRAP = {
  BALANCE: "balance",
  PRETTY: "pretty",
  WRAP: "wrap",
  NOWRAP: "nowrap",
};

export const TEXT_BORDER = {
  NONE: "none",
  ROUNDED: "rounded",
};

export const TEXT_DECORATION = {
  LINE_THROUGH: "line-through",
  UNDERLINE: "underline",
  NONE: "",
};

export const TEXT_ALIGN = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
  JUSTIFY: "justify",
};

export const TEXT_HEADING_LEVEL = {
  H1: 1,
  H2: 2,
  H3: 3,
  H4: 4,
  H5: 5,
  H6: 6,
};

export const TEXT_SIZE = {
  TITLE1: "title01",
  TITLE15: "title015",
  TITLE2: "title02",
  TITLE3: "title03",
  TITLE35: "title035",
  TITLE4: "title04",
  TITLE45: "title045",
  TITLE5: "title05",
  TITLE6: "title06",
  TITLE7: "title07",
  DISPLAY1: "display01",
  DISPLAY2: "display02",
  DISPLAY3: "display03",
  CAPTION1: "caption01",
  CAPTION2: "caption02",
  CAPTION3: "caption03",
  T1: "t1",
  T2: "t2",
  T3: "t3",
  T4: "t4",
  T5: "t5",
  T6: "t6",
  T7: "t7",
  SUBTITLE1: "subtitle1",
  SUBTITLE2: "subtitle2",
  BODY1: "body1",
  BODY2: "body2",
  CAPTION: "caption",
};

export const TEXT_SIZE_ENUMS = {
  T1: "t1",
  T2: "t2",
  T3: "t3",
  T4: "t4",
  T5: "t5",
  T6: "t6",
  T7: "t7",
  SUBTITLE1: "subtitle1",
  SUBTITLE2: "subtitle2",
  BODY1: "body1",
  BODY2: "body2",
  CAPTION: "caption",
};

export const TEXT_FONT_WEIGHT = {
  REGULAR: "font-regular",
  LIGHT: "font-light",
  BOLD: "font-bold",
};
