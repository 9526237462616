import React from "react";
import CustomLink from "@/bit/components/components.custom-link";
import Text from "@/bit/components/components.text";

const renderElemnts = (items, title, skeletonClassName) => {
  let i = 0;
  return (
    <>
      <nav>
        <ul className="breadcrumb" data-cy="Header breadcrumb">
          {items.map(([key, value]) => {
            const isReact = value && value.indexOf("react:") !== -1;
            const text = key;
            let link = value ? value : "#";
            if (isReact) {
              link = link.replace("react:", "");
            }
            return (
              <li
                key={`item-${i++}`}
                className={`breadcrumb-item breacrumb-item-value-${text.toLowerCase()}`}
              >
                <CustomLink
                  href={!isReact ? link : null}
                  title=""
                  ariaLabel=""
                  onClick={
                    isReact
                      ? () => {
                          let id2click = `#react-${key}`;
                          id2click = id2click.replace(/ /g, "");
                          document.querySelector(id2click).click();
                        }
                      : null
                  }
                  taggingInfo={{
                    componentName: "header",
                    componentContent: text,
                    componentVariation: "breadcrumb",
                    componentPerso: "",
                    componentProduct: "",
                  }}
                >
                  <Text
                    text={text}
                    fontType="font-regular"
                    size="subtitle2"
                    color="grey6"
                    display="inline"
                  />
                </CustomLink>
                <div className={skeletonClassName} />
              </li>
            );
          })}

          <li className="breadcrumb-item active">
            <Text
              text={title}
              fontType="font-regular"
              size="subtitle2"
              color="grey5"
              display="inline"
            />
            <div className={skeletonClassName} />
          </li>
        </ul>
        <style jsx>{`.breadcrumb {
  display: flex;
  line-height: 1.188rem;
  overflow: hidden;
  position: relative;

  .breadcrumb-item {
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    align-items: center;
    :global(.text) {
      text-wrap: nowrap;
      white-space: nowrap;
    }

    &.active {
      :global(.text) {
        text-wrap: wrap;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: wrap;
      }
    }

    :global(a) {
      &:hover {
        color: #313235 !important;
        text-decoration: solid underline currentColor 1px;
        text-underline-offset: 4px;
        text-decoration-skip-ink: none;
      }
    }

    &:nth-last-child(n + 3) {
      display: none;
    }

    &:not(:last-child)::after {
      display: inline-block;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      color: #6c757d;
      content: "/";
    }
    .skeleton {
      position: absolute;
      top: 12.5%;
      left: 0px;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) {
  .breadcrumb {
    .breadcrumb-item {
      &:nth-last-child(n + 3) {
        display: flex;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .breadcrumb {
    .breadcrumb-item {
    }
  }
}
`}</style>
      </nav>
    </>
  );
};

const Breadcrumb = (props) => {
  const { breadcrumb, extraBreadcrumbsProps, skeletonClassName } = props;
  if (!breadcrumb) {
    return null;
  }

  let items = Object.entries(breadcrumb["breadCrumbs"]);
  if (extraBreadcrumbsProps) {
    const another = Object.entries(extraBreadcrumbsProps);
    items.push(another[0]);
  }
  return <>{renderElemnts(items, breadcrumb.title, skeletonClassName)}</>;
};
export default Breadcrumb;
